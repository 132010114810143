import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import SideMenu from "./SideMenu";
import axios, { Axios } from "axios";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Drawer } from "@material-ui/core/";
import APIConfig from "../Config/APIConfig";
const drawerWidth = 500;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },

  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

const AboutUs = ({ updateUser }) => {
  const classes = useStyles();
  const history = useNavigate();
  const [user, setuser] = useState({
    page_title: "",
    shortdesc: "",
    fulldesc: "",
    email: "",
    contact_number: "",
    page_image: "",
  });

  const [page, setPage] = useState([]);
  const [open, setOpen] = useState(false);
  console.log("dsdfa", page[0]?.email);

  const getPage = () => {
    axios.get(APIConfig.getPage).then((res) => {
      const myPage = res.data.result;
      //  console.log(myPage)
      setPage(myPage);
    });
  };
  useEffect(() => {
    getPage();
  }, []);

  const handleInputs = (e) => {
    const { name, value } = e.target;

    setuser({ ...user, [name]: value });
  };

  const PostData = async (e) => {
    e.preventDefault();

    const {
      page_title,
      shortdesc,
      fulldesc,
      email,
      contact_number,
      page_image,
    } = user;

    if ((page_title, shortdesc, fulldesc, email, contact_number, page_image)) {
      const res = await fetch(APIConfig.insertPage, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          page_title,
          shortdesc,
          fulldesc,
          email,
          contact_number,
          page_image,
        }),
      });

      const data = await res.json();
      if (data.status === 400 || !data) {
        toast.error("Something Went Wrong");
      } else {
        toast.success(data.msg);
        getPage();
      }
    } else {
      toast.error("Fill all the fields");
    }
  };

  const [id, setId] = useState("");
  const [Title, setTitle] = useState("");
  const [ShortDescription, setShortDescription] = useState("");
  const [LongDescription, setLongDescription] = useState("");
  const [Email, setEmail] = useState("");
  const [MobNo, setMobNo] = useState("");
  // const [AboutImage, setAboutImage] = useState("");

  const handleDrawerOpen = (val) => {
    setOpen(true);
    console.log(val.page_id);
    setId(val.page_id);
    setTitle(val.page_title);
    setShortDescription(val.shortdesc);
    setLongDescription(val.fulldesc);
    setEmail(val.email);
    setMobNo(val.contact_number);
    // setAboutImage(val.page_image);
  };

  const handleDrawerClose = () => {
    setOpen(false);
    setOpenView(false);
  };

  const handlePageEdit = async (e) => {
    e.preventDefault();

    const res = await fetch(APIConfig.updatePage, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        page_id: id,
        page_title: Title,
        shortdesc: ShortDescription,
        fulldesc: LongDescription,
        email: Email,
        contact_number: MobNo,
      }),
    });

    const data = await res.json();

    if (data.status === "error") {
      toast.error(data.msg, {
        position: "bottom-right",
        theme: "colored",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      getPage();
      setOpen();
      toast.success(data.msg, {
        position: "bottom-right",
        theme: "colored",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  const [sliderImages, setSliderImages] = useState();

  // const getSliderImages = () => {
  //   axios.get(APIConfig.getSliderImage).then((res) => {
  //     const mySlider = res.data.url;
  //     setSliderImages(mySlider);
  //   });
  // };
  // useEffect(() => {
  //   getSliderImages();
  // }, []);
  const [openView, setOpenView] = useState(false);
  const handleUsersView = (val) => {
    setOpenView(true);
  };

  return (
    <div id="app">
      <SideMenu updateUser={updateUser} />
      <ToastContainer />

      <div id="main">
        {/* <!-- // Basic multiple Column Form section start --> */}
        <section id="multiple-column-form">
          <div className="row match-height">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">About Us</h4>
                </div>
                <div className="card-content">
                  <div className="card-body">
                    <form className="form">
                      <div className="row">
                        <div className="col-md-6 col-12">
                          <div className="form-group">
                            <label for="first-name-column">Title</label>
                            <input
                              type="text"
                              id="first-name-column"
                              className="form-control"
                              value={user.page_title}
                              onChange={handleInputs}
                              placeholder="Enter Page title"
                              name="page_title"
                            />
                          </div>
                        </div>
                        <div className="col-md-6 col-12">
                          <div className="form-group">
                            <label for="last-name-column">Email</label>
                            <input
                              type="email"
                              id="last-name-column"
                              className="form-control"
                              value={user.email}
                              onChange={handleInputs}
                              placeholder="Enter Email"
                              name="email"
                            />
                          </div>
                        </div>
                        <div className="col-md-6 col-12">
                          <div className="form-group">
                            <label for="city-column">Short Description</label>
                            <textarea
                              className="form-control"
                              value={user.shortdesc}
                              onChange={handleInputs}
                              placeholder="write one line separeted with comma"
                              id="floatingTextarea"
                              name="shortdesc"
                            ></textarea>
                          </div>
                        </div>
                        <div className="col-md-6 col-12">
                          <div className="form-group">
                            <label for="country-floating">Mobile No</label>
                            <input
                              type="number"
                              value={user.contact_number}
                              onChange={handleInputs}
                              id="country-floating"
                              className="form-control"
                              name="contact_number"
                              placeholder="Enter Mob Number"
                            />
                          </div>
                        </div>
                        <div className="col-md-6 col-12">
                          <div className="form-group">
                            <label
                              for="exampleFormControlTextarea1"
                              className="form-label"
                            >
                              Long Description
                            </label>
                            <textarea
                              className="form-control"
                              id="exampleFormControlTextarea1"
                              value={user.fulldesc}
                              onChange={handleInputs}
                              rows="3"
                              name="fulldesc"
                            ></textarea>
                          </div>
                        </div>
                        <div className="col-md-6 col-12">
                          <div className="form-group">
                            <label for="formFile" className="form-label">
                              {" "}
                              About image
                            </label>
                            <input
                              className="form-control"
                              value={user.page_image}
                              onChange={handleInputs}
                              type="file"
                              id="formFile"
                              name="page_image"
                            />
                          </div>
                        </div>

                        <div className="col-12 d-flex justify-content-end">
                          <button
                            type="submit"
                            className="btn btn-primary me-1 mb-1"
                            onClick={PostData}
                          >
                            Submit
                          </button>
                          <button
                            type="reset"
                            className="btn btn-light-secondary me-1 mb-1"
                          >
                            Reset
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="table-responsive">
            <table className="table bg-white rounded shadow-sm table-hover">
              <thead className="fixed">
                <tr>
                  <th scope="col">Serial Number</th>
                  <th scope="col">Title</th>
                  <th scope="col">Short Description</th>
                  <th scope="col">Long Description</th>
                  <th scope="col">Email</th>
                  <th scope="col">Mob No.</th>
                  <th scope="col">About Image</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>

              <tbody>
                {page.map((val, index) => {
                  return (
                    <tr>
                      <td>{index + 1}</td>
                      <td>{val.page_title}</td>
                      <td>{val.shortdesc}</td>
                      <td>{val.fulldesc}</td>
                      <td>{val.email}</td>
                      <td>{val.contact_number}</td>
                      <td>
                        {
                          <img
                            style={{ width: "200px" }}
                            src={
                              APIConfig.aboutImage +
                              val.page_image.slice(10, val.page_image.length)
                            }
                            alt=""
                          />
                        }
                        {console.log(
                          val.page_image.slice(10, val.page_image.length)
                        )}
                      </td>

                      <td
                        color="inherit"
                        aria-label="open drawer"
                        onClick={() => handleDrawerOpen(val)}
                        edge="start"
                        className={clsx(
                          classes.menuButton,
                          open && classes.hide
                        )}
                      >
                        <i className="fa-solid fa-pen-to-square"></i>
                      </td>

                      <td
                        color="inherit"
                        aria-label="open drawer"
                        onClick={() => handleUsersView(val)}
                        edge="start"
                        className={clsx(
                          classes.menuButton,
                          open && classes.hide
                        )}
                      >
                        <i className="fa-solid fa-eye"></i>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </section>
      </div>

      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="right"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <div className="card-header-right">
            <a
              onClick={handleDrawerClose}
              href="#"
              className="collapsed btn btn-sm waves-effect waves-light btn-primary m-0"
            >
              Close
            </a>
          </div>
        </div>

        <form className="form container">
          <div className="row">
            <div className="col-md-6 col-12">
              <div className="form-group">
                <label for="first-name-column">Title</label>
                <input
                  type="text"
                  id="first-name-column"
                  className="form-control"
                  onChange={(e) => setTitle(e.target.value)}
                  value={Title}
                  placeholder="Enter Page title"
                  name="page_title"
                />
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="form-group">
                <label for="last-name-column">Email</label>
                <input
                  type="email"
                  id="last-name-column"
                  className="form-control"
                  value={Email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter Email"
                  name="email"
                />
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="form-group">
                <label for="city-column">Short Description</label>
                <textarea
                  className="form-control"
                  value={ShortDescription}
                  onChange={(e) => setShortDescription(e.target.value)}
                  placeholder="write one line separeted with comma"
                  id="floatingTextarea"
                  name="shortdesc"
                ></textarea>
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="form-group">
                <label for="country-floating">Mob-No.</label>
                <input
                  type="number"
                  id="country-floating"
                  className="form-control"
                  value={MobNo}
                  onChange={(e) => setMobNo(e.target.value)}
                  name="country-floating "
                  placeholder="Enter Mob Number"
                />
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="form-group">
                <label for="exampleFormControlTextarea1" className="form-label">
                  Long Description
                </label>
                <textarea
                  className="form-control"
                  id="exampleFormControlTextarea1"
                  value={LongDescription}
                  onChange={(e) => setLongDescription(e.target.value)}
                  rows="3"
                  name="fulldesc"
                ></textarea>
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="form-group">
                <label for="formFile" className="form-label">
                  {" "}
                  About Image
                </label>
                <input
                  className="form-control"
                  //  value={AboutImage}
                  //  onChange={(e)=>setAboutImage(e.target.value)}
                  type="file"
                  id="formFile"
                  name="page_image"
                />
              </div>
            </div>
            <div className="form-group col-12">
              <div className="form-check">
                {/* <!-- <div className="checkbox">
                                                        <input type="checkbox" id="checkbox5" className='form-check-input'
                                                            checked>
                                                        <label for="checkbox5">Remember Me</label>
                                                    </div> --> */}
              </div>
            </div>
            <div className="col-12 d-flex justify-content-end">
              <button
                type="submit"
                className="btn btn-primary me-1 mb-1"
                onClick={handlePageEdit}
              >
                Submit
              </button>
              <button
                type="reset"
                className="btn btn-light-secondary me-1 mb-1"
              >
                Reset
              </button>
            </div>
          </div>
        </form>
      </Drawer>

      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="right"
        open={openView}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <div className="card-header-right">
            <a
              onClick={handleDrawerClose}
              //href="#"
              className="collapsed btn btn-sm waves-effect waves-light btn-primary m-0"
            >
              Close
            </a>
          </div>
        </div>

        <form method="GET container">
          <div className="row">
            <div className="col-md-6">
              <div className="tab-content profile-tab" id="myTabContent">
                <div
                  className="tab-pane fade show active"
                  id="home"
                  role="tabpanel"
                  aria-labelledby="home-tab"
                >
                  <div className="row">
                    <div className="col-md-6">
                      <label>Title:</label>
                    </div>
                    <div className="col-md-6">
                      <p>{Title}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <label>Short Description:</label>
                    </div>
                    <div className="col-md-6">
                      <p>{ShortDescription}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <label>Long Description:</label>
                    </div>
                    <div className="col-md-6">
                      <p>{LongDescription}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <label>Email:</label>
                    </div>
                    <div className="col-md-6">
                      <p>{Email}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <label>Mobile No.:</label>
                    </div>
                    <div className="col-md-6">
                      <p>{MobNo}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </Drawer>

      <footer
        style={{
          position: "fixed",
          bottom: "0",
          width: "100%",
          backgroundColor: "rgb(237, 236, 236)",
          color: "white",
        }}
      >
        <div className="footer clearfix mb-0 text-muted">
          <div className="float-start">
            <p>2021 &copy; Mazer</p>
          </div>
          <div className="float-end" style={{ right: "50px" }}>
            <p>
              Crafted with{" "}
              <span className="text-danger">
                <i className="bi bi-heart"></i>
              </span>{" "}
              by <a href="http://Rechargkit.com">Rechargkit</a>
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default AboutUs;
