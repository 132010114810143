const url = "http://localhost:3005/api";
const url2 = "http://localhost:3005";

const APIConfig = {
  login: `${url2}/login`,
  aboutImage: `${url2}/?image=`,
  productImage: `${url2}/getImage?image=`,
  teamImage: `${url2}/getTeamImage?image=`,
  galleryImage: `${url2}/getGalleryImage?image=`,
  eventImage: `${url2}/getOurEventImage?image=`,
  getPage: `${url}/getpage`,
  insertPage: `${url}/insertpage`,
  updatePage: `${url}/updatepage`,
  getCareer: `${url}/getcareer`,
  getbusinessPart: `${url2}/get_business_form1`,
  insertCareer: `${url}/insertcareer`,
  updateCareer: `${url}/updatecareer`,
  getProduct: `${url}/getproduct`,
  insertProduct: `${url}/insertproducts`,
  updateProduct: `${url}/updateproduct`,
  getSlider: `${url}/slider`,
  getCandidateList: `${url}/candidate_list`,
  updateCandidate: `${url}/candidate_update`,
  insertSlider: `${url}/insertslider`,
  deleteSlider: `${url}/deleteslider`,
  // getSliderImage: `${url2}/?image=api`,
  status: `${url2}/status`,
  getOurTeam: `${url}/getourteam`,
  insertOurTeam: `${url}/insertourteam`,
  updateOurTeam: `${url}/updateourteam`,
  getGallery: `${url2}/get_gallery`,
  insertGallery: `${url}/insert_gallery`,
  getEvents: `${url2}/get_events`,
  insertEvents: `${url2}/insert_events`,
  getMedia: `${url2}/get_media`,
  insertMedia: `${url2}/insert_media`,
  getUser: `${url}/getusers`,
  updateUser: `${url}/updateusers`,
  userRegister: `${url2}/register`,
};

export default APIConfig;
